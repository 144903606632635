import abi from "./contract/GlobalPool.json";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import Buy from "./component/buy";
import Memos from "./component/memos";
import './style.css';
import "./copy.png";
import Buy3 from "./component/buy3";
import './assets/css/bootstrap.css';
import './assets/js/bootstrap.js';

function App() {
  const [state, setState] = useState({
    provider: null,
    signer: null,
    contract: null,
  });
  
  const [account, setAccount] = useState("None");
  const[b,setB]=useState("None");
  const[direct,setDirect]=useState("None");
  const[directs,setDirects]=useState("None");
  const[level,setLevel]=useState("None");
  const[wlevel,setLevelw]=useState("None");

  const[wlevel1,setLevelw1]=useState("None");
  const[plevel,setLevelp]=useState("None");
  const[pack,setpackage]=useState("None");
  const[pack3,setpackage3]=useState("None");
  const[pack1,setpackage1]=useState("None");
  const[refer,setrefer]=useState("None");
  const[refer4,setrefer4]=useState("None");
  const[refer3,setrefer3]=useState("None");
  const[refer1,setrefer1]=useState("None");
  const[refer2,setrefer2]=useState("None");
const [tasks,setTasks] = useState([]);
const [tasks1,setTasks1] = useState([]);
const [tt,settt]=useState("None");
const [tt4,settt4]=useState("None");
const[dd,setDD]=useState("None");
const[dd1,setDD1]=useState("None");
const[dd2,setDD2]=useState("None");
const[referlink,setreflink]=useState("None");
const[bonanza,setBonanza]=useState("None");
const[upd,setupd]=useState("None");
const [tt1,settt1]=useState("None");
const [roi,seroi]=useState("None");
const [roi1,seroi1]=useState("None");
const [roi2,seroi2]=useState("None");
const [a1,sea1]=useState("None");
const [a2,sea2]=useState("None");
const [a3,sea3]=useState("None");
const [tt2,settt2]=useState("None");
const [tt3,settt3]=useState("None");
const[b1,setb1]=useState("None");
const[b2,setb2]=useState("None");
const[b3,setb3]=useState("None");
const[b4,setb4]=useState("None");
const[b5,setb5]=useState("None");
const[b6,setb6]=useState("None");
const[b7,setb7]=useState("None");
const[b8,setb8]=useState("None");
const[b9,setb9]=useState("None");
const[b10,setb10]=useState("None");
const[b11,setb11]=useState("None");
const[b12,setb12]=useState("None");
const[b13,setb13]=useState("None");
const[b14,setb14]=useState("None");
const[b15,setb15]=useState("None");
const[b16,setb16]=useState("None");
const[reward,setreward]=useState("None");

  useEffect(() => {
    
    const connectWallet = async () => {
      
      const contractAddress = "0x3703005ed5ff0d3b21e1169de535ff536ff8e246";
      const contractABI = abi.abi;
      const searchParams = new URLSearchParams(window.location.search);
    const myParam = searchParams.get('ref');
    console.log(myParam);
    document.querySelector("#name").value=myParam;
if(myParam == null)
  {
    document.querySelector("#name").value="0xb9DFdBB2845EDca4AcD938445EDC703cfB8C997A";
  }
  
  try {
    const { ethereum } = window;
  //  document.querySelector("#test").value = "0";
    if (ethereum) {
      const account = await ethereum.request({
        method: "eth_requestAccounts",
       
      });
     
      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });

      window.ethereum.on("accountsChanged", () => {
        
        window.location.reload();
      });
          const provider = new ethers.providers.Web3Provider(ethereum);
        
          try {
            const selectedNetwork = await provider.getNetwork();
          
            if (selectedNetwork.chainId !== 56) {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x38' }], // Binance Smart Chain Mainnet chainId is 97 in decimal, which is 0x61 in hex
              });
              console.log("You have switched to the Binance network");
            } else {
              console.log("You are already on the Binance network");
            }
          } catch (switchError) {
            // The network has not been added to MetaMask
            if (switchError.code === 4902) {
              console.log("Please add the Binance network to MetaMask");
            } else {
              console.log("Cannot switch to the network");
            }
          }
        
        
        
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            contractAddress,
            contractABI,
            signer
          );
          setAccount(account);
          setState({ provider, signer, contract });
          document.querySelector("#us").value  = account[0];
      let alltasks = await contract.getMemos(account[0]);
      setTasks(alltasks);
      var i = 0;
      const refer = (alltasks[1]);
      setrefer(refer);
      const a1 = Number(alltasks[0]);
      sea1(a1);
      const refer2 = (alltasks[23]);
     setrefer2(refer2);
 
      const b =Number(alltasks[2]);
     

     document.querySelector("#hid").value  = (alltasks[1]);
     const direct =Number(alltasks[2]);
     setDirect(direct);
     const directs = Number(alltasks[4])/1000000000000000000;
     setDirects(directs);
     const level = Number(alltasks[5])/1000000000000000000;
     setLevel(level);


     const plevel = Number(alltasks[21])/1000000000000000000;
     setLevelp(plevel);
     
    
     const pack = Number(alltasks[3]) ;

setpackage(pack);
const pack1 = Number(alltasks[3]) ;
document.querySelector("#pp").value  = Number(alltasks[3]);


setpackage1(pack1);



     setB(b);

     

     const dd = Number(alltasks[9]);
          
     setDD(dd);

     const reward = Number(alltasks[21])/1000000000000000000;
          
     setreward(reward);

     const dd2 = Number(alltasks[9]);
          
     setDD2(dd2);

     const dd1 =  Date.now() / 1000;
     setDD1(dd1);


     const tt1 = Math.trunc((dd1 - dd) / (3660 * 24 )) ;
   //  const tt1 =  (dd1.getTime() - dd.getTime())/ (1000 * 60 * 60 * 24);
     
     settt1(tt1);

     const tt2 = Math.trunc((dd1 - dd2) / (3600 * 24  )) ;
     //  const tt1 =  (dd1.getTime() - dd.getTime())/ (1000 * 60 * 60 * 24);
       
       settt2(tt2);
      
       document.querySelector("#tt2").value = tt2;
       
       const b1 = Number(alltasks[6]) ;
       setb1(b1);
       const b2 = Number(alltasks[7]) ;
       setb2(b2);
       const b3 = Number(alltasks[8]) ;
       setb3(b3);
       const b4 = Number(alltasks[9]) ;
       setb4(b4);
       const b5 = Number(alltasks[10]) ;
       setb5(b5);
       const b6 = Number(alltasks[11]) ;
       setb6(b6);
       const b7 = Number(alltasks[12]) ;
       setb7(b7);
       const b8 = Number(alltasks[13]) ;
       setb8(b8);
       const b9 = Number(alltasks[14]) ;
       setb9(b9);
       const b10 = Number(alltasks[15]) ;
       setb10(b10);

       const b11 = Number(alltasks[16]) ;
       setb11(b11);
       const b12 = Number(alltasks[17]) ;
       setb12(b12);
       const b13 = Number(alltasks[18]) ;
       setb13(b13);
       const b14 = Number(alltasks[19]) ;
       setb14(b14);
       const b15 = Number(alltasks[20]) ;
       setb15(b15);
       const b16 = Number(alltasks[28])/1000000000000000000;
       setb16(b16);

     const wlevel = Number(alltasks[25])/1000000000000000000 ;
          
 setLevelw(wlevel);
 const wlevel1 = Number(alltasks[26])/1000000000000000000;
          
 setLevelw1(wlevel1);

 

    // document.querySelector("#wi1").value = roi2;
     
    // const a1 = Number(alltasks1[0])-1;
          
    // sea1(a1);
    // const a3 = Number(alltasks1[2])/1000000000000000000;
          
   //  sea3(a3);
     const a2 = Number(alltasks[24]);
    sea2(a2);   
     var rr = 0;
    // const tt4 =  Number(alltasks[15])/1000000000000000000;
    // settt4(tt4);
     
     const tt =  Number(alltasks[27])/1000000000000000000;
     settt(tt);
     const tt3 = Number(alltasks[10])/1000000000000000000;
     settt3(tt3);
     document.querySelector("#bal").value = tt3;
     const referlink = 'https://maxgrowthplus.com/?ref='+ account;
     setreflink(referlink);
     document.querySelector("#link").value =referlink;


     const upd = b1+b2+b3+b4+b5+b6+b7+b8+b9+b10+b11+b12+b13+b14+b15;
          
     setupd(upd);


console.log(pack);

for(i=0;i<10;i++)
  {
 let alltasks1 = await contract.getMemos(account[i]);
setTasks1(alltasks1);
const refer1 = (alltasks1[1]);
setrefer1(refer1);
const refer3 = (alltasks1[23]);
setrefer3(refer3);

const pack1 = (alltasks1[3]);
setpackage1(pack1);


var refer5 = "";
if(refer1==refer2)
{
 console.log(refer3);

 document.querySelector("#roi").value  =  '\n' + "Connected Account " + refer3 + '\n'  + document.querySelector("#roi").value;
 const refer4 = document.querySelector("#roi").value;
 setrefer4(refer4);
}

}



 
        } else {
          alert("Please install metamask");
        }
      } catch (error) {
        console.log(error);
      }
    };
    
   
    
    connectWallet();
    
    function myFunction() {
      
      var copyText = "hello";
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      
    
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.valueOf);
    }

  }, []);

 
  // console.log(state);
  return (
  <div>
    <input type="hidden" id ="link"></input>
    <input type="hidden" id ="hid"></input>
    <input type="hidden" id ="us"></input>
    <input type="hidden" id ="pp"></input>
    <input type="hidden" id ="tt2"></input>
    <input type="hidden" id ="wi1"></input>
    <input type="hidden" id ="roi"></input>
    <input type="hidden" id ="bal"></input>
   
<div class="se-pre-con"></div>
  
<header>



<div class="main-menu">
    <nav class="navbar navbar-expand-lg navbar-light bg-transparent">
        <div class="container">
          <a href="#" class="logo">
          <img src= "logo.png" width="250px" height="250px"></img>
          </a>
         
        </div>
      </nav>
</div>


</header>



<div class="banner-area">
  <div class="container">
    <div class="row justify-content-end">
      <div class="col-md-6">
        <div class="banner-txt">
          <h2>WELCOME
TO MAX GROWTH</h2>
          <p>Secured by cryptographic principles,
ensuring transparency and immutability.
          </p>
          <ul>
            <li><i class="las la-check-circle"></i> Blockchain decentralized and anonymous platform  </li>
            <li><i class="las la-check-circle"></i> Totally secure income based on  DAO smart-contract</li>
            <li><i class="las la-check-circle"></i> Smart-contract verified and audited by independent company  </li>
          </ul>
          <a href="#">My Account </a>
          <p> {account}</p>
        </div>
      </div>
    </div>

  </div>
</div>


<div class="col-md-4">
<a href="#" class="foot-btn">My Package : {pack}</a>

          <a href="#" class="foot-btn">
         
                            Direct Team : {direct}
                            </a>
          <a href="#" class="foot-btn">

          
                            Direct Income : {directs}
          </a>
          <a href="#" class="foot-btn">

          
                            Pending Income For Upgrade : {b16}
          </a>
        </div>
        <br></br>
        <div class="container">
    <div class="row">
    <div class="title">
      <h3>JOIN NOW    </h3>
      <input type="text" id ="test1"></input><br></br><br></br>
      <Buy state={state}  /> 

      <br></br><br></br>
      
      <h3>WITHDRAWL    </h3>
      <input type="text" id ="test"></input><br></br><br></br>
      <Buy3 state={state}  /> 
   </div></div></div>
<div class="container">
    <div class="row">
        <div class="title">
            <h3>ACCOUNT<span></span><span>DETAILS</span></h3>
            
        </div>
    </div>



<div class="row">
  <div class="col-md-4">
    <div class="fund-box">
      <h3>Level Income     </h3>
      <p>   {level} </p>
   
      <h3>Total Team     </h3>
      <p>  {upd + direct}  </p>

      <h3>MY PURCHASE    </h3>
      <p>  {pack}  </p>
   
    </div>
  </div>



  <div class="col-md-4">
    <div class="fund-box">
    <h3>Royalty Income    </h3>
      <p>  {reward} USDT</p>
      <h3>Crowd Fund    </h3>
      <p>  {wlevel} USDT </p>
      <h3>Direct Eligble For Royalty    </h3>
      <p>  {a2}  </p>
   


    </div>
  </div>


  <div class="col-md-4">
    <div class="fund-box">

       <h3>TOTAL INCOME
       </h3>
      <p>  {tt} USDT </p>

      <h3>TOTAL WITHDRAWL
      </h3>
     <p>  {wlevel1} USDT </p>
      
     
      <h3>PENDING INCOME
      </h3>
      <p>  {tt - wlevel1} USDT </p>

    </div>
  </div>
  

  
   
</div>



</div>




<div class="container">
<div class="row mt-2 mb-3 justify-content-center">

  <div class="col-md-6" >

    <div class="step-1">
    <div class="step-title">
      REFER LINK

    </div>
   <div class="step-box">
<p>
    {referlink} <button><img src ="copy.png" height={"25px"} onClick={() => {
         navigator.clipboard.writeText(referlink);}} /></button></p>
   </div>


   <div class="step-box">
    <h3>MY REFER</h3>
    <p>{refer}</p>
   </div>
   <div class="step-box">
   <h3>MY DIRECT TEAM</h3>
   <a href="#">
{refer4}
   </a>
</div>
   <div class="step-box">

    
<table width="100%">
<tr>
<td width="33%"><h3>
Level</h3>
</td>
<td width="33%"><h3>
Team Size</h3>
</td>

</tr>
<tr>
  <td><a href="#">1</a></td> <td><a href="#">{b1}</a></td> 
</tr>
<tr>
  <td><a href="#">2</a></td> <td><a href="#">{b2}</a></td> 
</tr>
<tr>
  <td><a href="#">3</a></td> <td><a href="#">{b3}</a></td> 
</tr>
<tr>
  <td><a href="#">4</a></td> <td><a href="#">{b4}</a></td> 
</tr>
<tr>
  <td><a href="#">5</a></td> <td><a href="#">{b5}</a></td> 
</tr>
<tr>
  <td><a href="#">6</a></td> <td><a href="#">{b6}</a></td> 
</tr>
<tr>
  <td><a href="#">7</a></td> <td><a href="#">{b7}</a></td> 
</tr>
<tr>
  <td><a href="#">8</a></td> <td><a href="#">{b8}</a></td> 
</tr>
<tr>
  <td><a href="#">9</a></td> <td><a href="#">{b9}</a></td> 
</tr>
<tr>
  <td><a href="#">10</a></td> <td><a href="#">{b10}</a></td> 
</tr>
<tr>
  <td><a href="#">11</a></td> <td><a href="#">{b11}</a></td> 
</tr>
<tr>
  <td><a href="#">12</a></td> <td><a href="#">{b12}</a></td>
</tr>
<tr>
  <td><a href="#">13</a></td> <td><a href="#">{b13}</a></td> 
</tr>
<tr>
  <td><a href="#">14</a></td> <td><a href="#">{b14}</a></td> 
</tr>
<tr>
  <td><a href="#">15</a></td> <td><a href="#">{b15}</a></td> 
</tr>
</table>

  </div>
  </div>
 
  











  </div>


</div>
</div>








    <footer>
      <div class="container">
      <div class="row align-items-center">
        <div class="col-md-2">

          <a href="#" class="logo">
            <span>M</span> AXGROWTH
          </a>
          
        </div>
  
       
  
        
  

        <div class="col-md-6">
          <p class="copyright">© 2024 All rights reserved <span>MaxGrowth</span></p>
        </div>

      </div>
  
  
    </div>
    </footer>
  


<br></br>
<br></br>
</div>  
  );
}

export default App;